// Webpack Imports
import * as bootstrap from 'bootstrap';

(function () {
    'use strict';

    // Focus input if Searchform is empty
    [].forEach.call(document.querySelectorAll('.search-form'), (el) => {
        el.addEventListener('submit', function (e) {
            var search = el.querySelector('input');
            if (search.value.length < 1) {
                e.preventDefault();
                search.focus();
            }
        });
    });

    // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: 'focus',
        });
    });
})();

// Dropdown on hover
document.addEventListener('DOMContentLoaded', function () {
    if (window.innerWidth >= 1200) {
        document.querySelectorAll('.navbar .nav-item').forEach(function (everyitem) {
            everyitem.addEventListener('mouseover', function (e) {
                let el_link = this.querySelector('a[data-bs-toggle]');

                if (el_link != null) {
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.add('show');
                    nextEl.classList.add('show');
                }
            });
            everyitem.addEventListener('mouseleave', function (e) {
                let el_link = this.querySelector('a[data-bs-toggle]');

                if (el_link != null) {
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.remove('show');
                    nextEl.classList.remove('show');
                }
            });
        });
    }
});
(function () {
    $(window).load(function () {
        $('#preloader').addClass('out');
        setTimeout(function () {
            $('#preloader').hide();
        }, 700);
    });

    jQuery(document).ready(function ($) {
        $('.navbar-custom-toggler').click(function () {
            $(this).toggleClass('open');
            $('.mobile-navbar').toggleClass('open');
        });

        $('.gallery-row').magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {enabled: true, tCounter: '<span class="mfp-counter">%curr% z %total%</span>', preload: [1, 2]},
            callbacks: {
                buildControls: function () {
                    // re-appends controls inside the main container
                    this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
                },
            },
        });

        $('.slider-apartament').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            prevArrow: `<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="8.485" height="14.142" viewBox="0 0 8.485 14.142"><path id="chevron-left" d="M16.243,6.343,14.828,4.929,7.757,12l7.071,7.071,1.414-1.414L10.586,12Z" transform="translate(-7.757 -4.929)"/></svg></button>`,
            nextArrow: `<button type="button" class="slick-next"><svg id="chevron-right" xmlns="http://www.w3.org/2000/svg" width="8.485" height="14.142" viewBox="0 0 8.485 14.142"><path id="chevron-right-2" data-name="chevron-right" d="M10.586,6.343,12,4.929,19.071,12,12,19.071l-1.414-1.414L16.243,12Z" transform="translate(-10.586 -4.929)"/></svg></button>`,
            dots: true,
            mobileFirst: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: true,
                    },
                },
            ],
        });
        // Map
        if ($('#map-wrapper').length) {
            var map = L.map('map-wrapper', {scrollWheelZoom: false, dragging: !L.Browser.mobile, tap: !L.Browser.mobile}).setView([53.5823291, 21.6651004], 10);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(map);

            var greenIcon = L.icon({
                iconUrl: 'https://dobrachata.pl/wp-content/themes/dobra-chata/assets/img/map-marker.svg',
                iconSize: [30, 40],
                iconAnchor: [30, 40],
                iconAnchor: [15, 40],
            });

            L.marker([53.5823291, 21.6651004], {icon: greenIcon}).addTo(map);
        }
    });
    // Navbar scroll
    $(window).on('scroll', function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 10) {
            $('.navbar').addClass('navbar--scroll');
        } else {
            $('.navbar').removeClass('navbar--scroll');
        }
    });
})();
